<!--
 * @Description: 报价方案
 * @Author: zhang zhen
 * @Date: 2023-04-27 09:50:47
 * @LastEditors: zhang zhen
 * @LastEditTime: 2023-04-27 10:40:20
 * @FilePath: /page-sass/src/views/quotePriceManagement/quotePriceOrderInfo.vue
-->
<template>
  <div class="quotePriceOrderInfo">
    <h3 class="main-title">报价方案</h3>
    <div class="cover-box">
      <h3 class="title">报价信息</h3>
      <a-form-model class="basicInfo-form">
        <a-row :gutter="80" class="basicInfo-form-item">
          <a-col :span="6">
            <a-form-model-item label="报价类型">
              {{ '设计' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否含税">
              {{ '否' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否包含运输">
              {{ '否' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="运费"> {{ '500' || '0' }}元 </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="是否提供发票">
              {{ '否' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="厂家所在区域">
              {{ '华北地区' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="单价"> {{ '20' || '-' }} 元 </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="产品数量">
              {{ '20' || '-' }}
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="产品总价"> {{ '20' || '-' }} 元 </a-form-model-item>
          </a-col>
        </a-row>
        <a-row gutter="80" class="basicInfo-form-item">
          <a-col :span="24">
            <a-form-model-item label="报价说明">
              <div style="text-indent: 20px;">
                Occaecat pariatur nostrud eiusmod sunt officia pariatur nostrud aliquip in. Officia amet adipiscing
                tempor laborum elit quis voluptate aliqua ea voluptate aliquip aliqua veniam. Quis dolore et amet
                adipiscing dolor aute tempor eu ullamco cillum do nulla tempor aute nostrud. Ex aliquip sed dolor ipsum
                lorem
              </div>
            </a-form-model-item>
          </a-col>
          <a-col :span="24">
            <a-form-model-item label="文件">
              <div class="img-cover">
                <svg
                  t="1682562636698"
                  class="icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="5359"
                  width="90"
                  height="90"
                >
                  <path
                    d="M160 0h512l256 256v704c0 35.3472-28.6528 64-64 64H160c-35.3472 0-64-28.6528-64-64V64c0-35.3472 28.6528-64 64-64z"
                    fill="#7C8EEE"
                    p-id="5360"
                  ></path>
                  <path
                    d="M702.2976 579.2896l-298.5664 177.984c-19.9488 12.0192-45.3312-2.4128-45.3312-25.856v-355.968c0-22.848 25.3824-37.2736 45.3312-25.856l298.56 177.984c19.3408 12.032 19.3408 40.288 0 51.712z"
                    fill="#FFFFFF"
                    p-id="5361"
                  ></path>
                  <path d="M672 0l256 256h-192c-35.3472 0-64-28.6528-64-64V0z" fill="#CAD1F8" p-id="5362"></path>
                </svg>
                <a-button type="primary">下载报价参数</a-button>
              </div>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <div class="cover-box">
      <h3 class="title">服务与售后</h3>
      <a-form-model
        v-bind="{
          labelCol: { span: 3 },
          wrapperCol: { span: 16 }
        }"
      >
        <a-form-model-item
          label="交易保障"
          style="text-indent: 10px;"
        >
          <div class="tag-list">
            <div
              class="tag-list-item"
              v-for="i in tagListA"
              :key="i.value"
            >
              {{ i.label }}
            </div>
          </div>
        </a-form-model-item>
        <a-form-model-item
          label="特色服务"
          style="text-indent: 10px;"
        >
          <div class="tag-list">
            <div
              class="tag-list-item"
              v-for="i in tagListB"
              :key="i.value"
            >
              {{ i.label }}
            </div>
          </div>
        </a-form-model-item>
      </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
  name: 'quotePriceOrderInfo',
  data() {
    return {
      tagListA: [
        {
          label: '方案不满意退款',
          value: '11'
        },
        {
          label: '电话随时联系',
          value: '12'
        },
        {
          label: '14天交货',
          value: '13'
        }
      ],
      tagListB: [
        {
          label: '来样加工',
          value: '21'
        },
        {
          label: '免费定制',
          value: '22'
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.quotePriceOrderInfo {
  width: 875px;
  border-radius: 8px;
  background: #fffff8;
  margin: 0 auto;
  padding: 15px;
  .main-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .cover-box {
    background-color: #fff;
    padding: 15px;
    border: 1px solid #ddd;
    margin-bottom: 15px;
    border-radius: 4px;
    .title {
      font-size: 15px;
      margin-bottom: 20px;
    }
  }
  ::v-deep .ant-form-item-label {
    line-height: 25px;
    label {
      color: #626161 !important;
    }
  }
  ::v-deep .ant-form-item-control {
    line-height: 25px;
    color: #333;
  }
  .basicInfo-form {
    &:not(:last-of-type) {
      margin-bottom: 30px;
    }
    &-item {
      padding: 0 20px;
    }
  }
}
div.img-cover {
  cursor: pointer;
  height: 90px;
  display: flex;
  margin-top: 8px;
  align-items: flex-end;
}

.tag-list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    grid-gap: 15px 24px;
    &-item {
      border-radius: 6px;
      width: 140px;
      height: 60px;
      text-align: center;
      border: 1px solid #8fc6fc;
      color: #53a6fa;
      cursor: pointer;
      line-height: 58px;
      background-color: #e4f2fe;
      font-size: 12px;
    }
  }
</style>
